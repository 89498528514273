import React from 'react'

const LoadingState = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-white to-blue-50">
      <div className="flex flex-col items-center space-y-6 p-8 rounded-xl">
        {/* Company logo */}
        <div className="mb-4">
          <img 
            src="/logo.svg" 
            alt="Cloud Gateway" 
            className="h-12 w-auto"
            onError={(e) => {
              // Fallback if logo doesn't load
              e.currentTarget.style.display = 'none'
            }}
          />
        </div>
        
        {/* @INFO: Custom loading animation with brand colors */}
        <div className="relative w-24 h-24">
          {/* Outer ring */}
          <div className="absolute inset-0 border-4 border-t-transparent border-r-[#00a895] border-b-[#00577e] border-l-[#00a895] rounded-full animate-spin"></div>
          
          {/* Middle ring */}
          <div className="absolute inset-2 border-4 border-t-[#00577e] border-r-transparent border-b-[#00577e] border-l-transparent rounded-full animate-spin" style={{ animationDuration: '1.5s' }}></div>
          
          {/* Inner dot */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-4 h-4 bg-[#00a895] rounded-full animate-pulse"></div>
          </div>
        </div>
        
        {/* Text with brand styling */}
        <div className="text-center">
          <p className="text-darkblue font-display text-xl font-semibold">Loading...</p>
        </div>
        
      </div>
    </div>
  )
}

export default LoadingState 